import {
  all,
  fork,
  call,
  put,
  takeLatest,
  SagaReturnType,
  select,
} from 'redux-saga/effects';

import { portfolioActions } from './slice';
import mainApiProtected from '../../services/mainApiProtected';
import { PortfolioProduct } from './types';
import { formatIndexChartData } from '../../utils/chart';
import { transactionsActions } from '../Transactions/slice';
import { changeUserCurrency } from '../User/slice';
import { FundsState } from '../Funds/types';

function* fetchPortfolioChart() {
  try {
    const {
      data,
    }: SagaReturnType<typeof mainApiProtected.fetchPortfolioChart> = yield call(
      mainApiProtected.fetchPortfolioChart
    );

    if (data !== null) {
      const chartData = formatIndexChartData(data);

      yield put(portfolioActions.fetchChartSuccess(chartData));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchPortfolioList() {
  try {
    const response: SagaReturnType<
      typeof mainApiProtected.fetchPortfolioList
    > = yield call(mainApiProtected.fetchPortfolioList);

    const {
      funds: {
        BTC: { price: btcPrice },
      },
    }: FundsState = yield select((state) => state.funds);

    const data: PortfolioProduct[] = response.data.map((item) => {
      let changeTotal = (item.product.value - item.initialValue) * item.amount;
      let changePercent =
        ((item.product.value - item.initialValue) / item.initialValue) * 100;
      let changeCurrencyTotal =
        (item.product.valueInCurrency - item.initialValueCurrency) *
        item.amount;
      let changeCurrencyPercent =
        ((item.product.valueInCurrency - item.initialValueCurrency) /
          item.initialValueCurrency) *
        100;
      let valuePercent = 0;

      if (item.symbol === 'VUSDC') {
        valuePercent = item.product.value;
        item.product.value = 1;
        item.initialValue = 1;
        changeTotal = item.amountWithProfit - item.amount;
        changePercent =
          ((item.amountWithProfit - item.amount) / item.amount) * 100;
        changeCurrencyTotal = changeTotal * item.product.valueInCurrency;
        changeCurrencyPercent = changePercent;
        item.product.baseCurrency = 'USDC';
      }

      if (item.symbol === 'VDARC') {
        valuePercent = item.product.value;
        item.product.value = item.product.valueInCurrency / btcPrice;
        changeTotal =
          item.product.value * item.amountWithProfit -
          item.initialValue * item.amount;
        changePercent =
          ((item.amountWithProfit * item.product.value -
            item.amount * item.initialValue) /
            (item.amount * item.initialValue)) *
          100;
        changeCurrencyTotal =
          item.product.valueInCurrency * item.amountWithProfit -
          item.initialValueCurrency * item.amount;
        changeCurrencyPercent =
          ((item.amountWithProfit * item.product.valueInCurrency -
            item.amount * item.initialValueCurrency) /
            (item.amount * item.initialValueCurrency)) *
          100;
        item.product.baseCurrency = 'BTC';
      }

      return {
        ...item.product,
        initialValue: item.initialValue,
        initialValueCurrency: item.initialValueCurrency,
        changeCurrencyTotal,
        changeCurrencyPercent,
        changeTotal,
        changePercent,
        amount: item.amount,
        created: item.created * 1000,
        amountWithProfit: item.amountWithProfit,
        valuePercent,
      };
    });

    const sortData = data.sort((a, b) => {
      if (a.amount * a.valueInCurrency > b.amount * b.valueInCurrency) {
        return -1;
      }
      if (a.amount * a.valueInCurrency < b.amount * b.valueInCurrency) {
        return 1;
      }
      return 0;
    });

    yield put(portfolioActions.fetchSuccess(sortData));
  } catch (e) {
    yield put(portfolioActions.fetchFailure((e as any).message));
  }
}

function* watchFetchPortfolioList() {
  yield takeLatest(
    [
      'LOAD_APP',
      portfolioActions.fetch.type,
      portfolioActions.buy.type,
      portfolioActions.sell.type,
      transactionsActions.canceled.type,
      changeUserCurrency.type,
    ],
    fetchPortfolioList
  );
}

function* watchFetchPortfolioChart() {
  yield takeLatest(
    ['LOAD_APP', portfolioActions.fetchChart.type, changeUserCurrency.type],
    fetchPortfolioChart
  );
}

export default function* portfolioSaga() {
  yield all([fork(watchFetchPortfolioList), fork(watchFetchPortfolioChart)]);
}
